@import "/node_modules/toastify-js/src/toastify.css";
@import "~@fortawesome/fontawesome-pro/css/all";
@import "~@fortawesome/fontawesome-pro/css/fontawesome";
@import "~@fortawesome/fontawesome-pro/css/solid";
@import "~@fortawesome/fontawesome-pro/css/regular";
@import "~@fortawesome/fontawesome-pro/css/light";
@import "~@fortawesome/fontawesome-pro/css/brands";


// Variables
@import './variables';


// Bootstrap
@import '~bootstrap/scss/bootstrap';
body, html{
    background-color: #fff;
    overflow-x: hidden;
    width: 100%;
    height: 100dvh;
    position: relative;
    //enable toch actions
    -webkit-overflow-scrolling: touch;
    touch-action: auto;
    //prevent overflow x
    overflow-x: hidden;
    //prevent scroll bar
    scrollbar-width: none;
    -ms-overflow-style: none;
    //prevent scroll bar
    &::-webkit-scrollbar {
        display: none;
    }
}
.hidden{
    display: none !important;
}
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 -1px 0 #ddd;
}
input:hover {
    border-color: none !important;
    box-shadow: none !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.scrollable-transparent::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
    background-color: #ffffff;
  }

  .scrollable-transparent::-webkit-scrollbar {
    width: 1px;
    height: 0px;
    background-color: #ffffff;
  }

  .scrollable-transparent::-webkit-scrollbar-thumb {
    background-color: #ffffff;
}
/* scroll-bar width */
::-webkit-scrollbar,
.scrollable::-webkit-scrollbar {
    width: 4px;
}
.scrollable, .scrollable-transparent{
    overflow-y: scroll;
}
/* scroll-bar Track */
::-webkit-scrollbar-track,
.scrollable::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* scroll-bar Handle */
::-webkit-scrollbar-thumb,
.scrollable::-webkit-scrollbar-thumb {
    background: #888;
}

/* scroll-bar Handle on hover */
::-webkit-scrollbar-thumb:hover,
.scrollable::-webkit-scrollbar-thumb:hover {
    background: #c0c0c0;
}
#layout-app{
    min-height: 100dvh;
}
#ridder-feet{
    background-color: #E6E6E6;
    color: #000;
    padding: 3px 0;
    text-decoration: none;
    cursor: pointer;
    transition: .3s;
    font-size: 12px;
    font-weight: bold;
    &:hover,
    &:focus{
        background-color: #CCC;
    }
}
.btn{
    border-radius: 28px !important;
}
.btn-primary{
    color: $white;
    font-weight: 600;
    font-size: 1em;
    background-color: $bg-primary !important;
    &:hover,
    &:focus{
        background-color: $primary !important;
        border: 1px solid $primary !important;
    }
}
.btn-success{
    color: $white;
}
.input-group{
    border-radius: 2px;
    &.password-input{
        input{
            border-right: none;
        }
        .input-group-append{
            border-radius: 2px;
            border: 1px solid #ced4da;
            font-size: 1.2em;
            color: #444;

        }
    }

}
i{
    cursor: pointer;
    transition: .3s;
    &:hover,
    &:focus{
        color: $secondary;
    }
    &.fa-trash-can{
        &:hover{
            color: #000000 !important;
        }

    }
    &.fa-ban {
        color: $danger !important;
        &:hover{
            color: $danger !important;
        }
    }
}
.general-padding {
    width: 100%;
    padding: 0 3vw;
}
.general-body-padding{
    width: 100%;
    padding: 0 10vw;
}
.custom-space {
    letter-spacing: 1.68px;
}
.image-input-list{
    margin: 1vh 0 0 0;
    padding: 0;
    list-style: none;
    .image-input-list-item{
        margin: 1vh 5px;
        display: inline-block;
        vertical-align: middle;
        width: min-content;

    }
}

.image-container{
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 1px dotted $primary;
    border-radius: 3px;
    cursor: pointer;
    transition: .3s;
    &:hover,
    &:focus,
    &:hover .image-title,
    &:focus .image-title{
        color: $secondary;
    }
}

.image-title{
    font-size: .9em;
    text-align: center;
    transition: .3s;
}
.image-input{
    display: none;
}
.scrollable-transparent::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
    background-color: #ffffff;
}

.scrollable-transparent::-webkit-scrollbar
{
    width: 1px;
    height: 0px;
    background-color: #ffffff;
}

.scrollable-transparent::-webkit-scrollbar-thumb
{
    background-color: #ffffff;
}
.Scrollable, .Scrollable2, .scrollable-transparent{
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .fa{
    transition: .3s;
    &:hover{
        transform: scale(1.05);
    }
  }
  .pagination{
    li{
        cursor: pointer;
    }
  }
//center div
.centered{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.gm-style .gmnoprint {
    display: none;
}
.form-control, .form-select,.crud-current-selected-input,.crud-item-add-input{
    border-radius: 0;
    border: none;
    border-bottom: 1px solid $gray-secondary;
    padding: 0 0 1px 3px;
    color: $text-imput;
    font-weight: 500;
    //placeholder color
    &::placeholder{
        color: $text-place-holder;
        opacity: 0.5;
    }
}

.btn-secondary{
    color: $text-white;
    &:hover{
        color: $text-white;
    }
}
.input-color{
    visibility: hidden;
    width: 0;
    height: 0;
}
.input-password {
    cursor: pointer;
}

.toastify {
    font-weight: 600;
}
.is-invalid{
    box-shadow: none !important;
    border-color: $danger !important;
}
.form-check-input{
    width: 19px;
    height: 19px;
    border-color: $text-gray;
    border-width: 2px;
}
.pagination{
    .page-item{
        cursor: pointer;
    }
}
#loader-icon{
    margin-left: 5px;
    vertical-align: top;
}
table{
    thead{
        tr{
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 20px;
            th{
                vertical-align: middle;
                color: $text-red !important;
                font-size: 1em;
                padding: 10px !important;
            }
        }
    }
    tbody{
        tr{

            td{
                transition: .3s;
                p{
                    margin: 0;
                    padding: 0;
                    display: block;
                    width: 100%;
                    font-size: 1em;
                    color: $text-gray;
                }
                &.active-col{
                    .active-status{
                        border-radius: 13px;
                        &.active-status-0{
                            background-color: #FFF;
                            color: $text-black;
                            -webkit-box-shadow: inset 2px 4px 20px 0px rgba(0,0,0,0.17);
                            -moz-box-shadow: inset 2px 4px 20px 0px rgba(0,0,0,0.17);
                            box-shadow: inset 2px 4px 20px 0px rgba(0,0,0,0.17);
                        }
                        &.active-status-1{
                            background-color: #10BE16;
                            color: #FFF;
                            -webkit-box-shadow: inset 2px 4px 20px 0px rgba(0,0,0,0.17);
                            -moz-box-shadow: inset 2px 4px 20px 0px rgba(0,0,0,0.17);
                            box-shadow: inset 2px 4px 20px 0px rgba(0,0,0,0.17);
                        }
                    }
                }
            }
            &.active{
                td{
                    background-color: $secondary-bg;
                }

            }
            &.deleted{
                td:not(:last-child){
                    opacity: .3;
                }
            }
        }
    }
    .action-cell{
        i{
            margin: 0 5px;
            font-size: 21px;
            color: $text-gray-light;
            &:hover{
                color: $bg-primary;
            }
        }
    }

}
.form-switch{

    .form-check-input{
        width: 50px !important;
        cursor: pointer;
        &:checked{
            background-color: $secondary !important;
            border-color: $secondary !important;
        }
    }
}
.toggle-container{
    width: 140px;
    background: #F2F2F2 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 13px;
    text-align: center;
    padding: 0;
    .toggle-value{
        font-size: 0.9em;
        align-self: center;
        margin: 0;
        padding: 0;
        cursor: pointer;
        transition: .3s;
        border-radius: 13px;
        height: 100%;
        p{
            margin: 0;
            padding: 0;
            display: block;
            width: 100%;
            align-self: center;
        }
        &.inactive{
            color: $text-gray;
            P{
                font-weight: 500;
            }
        }
        &.active{
            p{
                font-weight: 600;
            }
        }
        &.active[value="0"]{
            background-color: #FFF;

            color: $text-black;
            -webkit-box-shadow: inset 2px 4px 20px 0px rgba(0,0,0,0.17);
            -moz-box-shadow: inset 2px 4px 20px 0px rgba(0,0,0,0.17);
            box-shadow: inset 2px 4px 20px 0px rgba(0,0,0,0.17);
        }
        &.active[value="1"]{
            background-color: #10BE16;
            color: #FFF;
            p{
                color: #FFF;
            }
            -webkit-box-shadow: inset 2px 4px 20px 0px rgba(0,0,0,0.17);
            -moz-box-shadow: inset 2px 4px 20px 0px rgba(0,0,0,0.17);
            box-shadow: inset 2px 4px 20px 0px rgba(0,0,0,0.17);
        }
    }
}
.pagination{
    select{
        padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
    }
}
//style to disabled input
input:disabled{
    background-color: transparent !important;
    opacity: 0.3  !important;
    cursor: not-allowed  !important;
}
.fa-copy{
    padding: 0 5px;
}
.division-line{
    width: 100%;
    height: 1px;
    background-color: $bg-secondary;

}
.page-link{

    select{
        padding: 0 !important;
        border: none;
        cursor: pointer;
        //remove border on hover
        &:hover, &:active, &:focus{
            border: none;
        }
    }
}
/* Styling for checkboxes */
input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background: #fff;
    border: 1px solid $gray-primary;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
}

input[type="checkbox"]:checked {
    background: #fff;
    border: 1px solid $gray-primary;
}

input[type="checkbox"]:checked::after {
    content: '✔';
    color: $gray-primary;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 8px;
    font-weight: bold;
}

/* Styling for radio buttons */
input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background: #fff;
    border: 1px solid $gray-primary;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    position: relative;
}

input[type="radio"]:checked {
    background: #fff;
    border: 1px solid $gray-primary;
}

input[type="radio"]:checked::after {
    content: '';
    width: 10px;
    height: 10px;
    background: $gray-primary;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
select:disabled{
    background-color: transparent !important;
    opacity: 0.3  !important;
    cursor: not-allowed  !important;
    border-bottom: none !important;
}
.order-state-0-text{
    color: #9B9B9B;
}
.order-state-0-bg{
    background-color: #9B9B9B;
}
.order-state-1-text{
    color: #C6B31F;
}
.order-state-1-bg{
    background-color: #C6B31F;
}
.order-state-2-text{
    color: #C61F1F;
}
.order-state-2-bg{
    background-color: #C61F1F;
}
.order-state-3-text{
    color: #1F66C6;
}
.order-state-3-bg{
    background-color: #1F66C6;
}
.order-state-4-text{
    color: #1FC645;
}
.order-state-4-bg{
    background-color: #1FC645;
}
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 1000;
  }
  .modal.active {
    display: flex;
  }
  .modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 90%;
    text-align: center;
  }
